import breadCrumbsIcon from "../../assets/images/breadcrumbs-icon.svg";
import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {ContextProvider} from "../../context";

const linkStyle = { marginRight: '15px', fontSize: '11px', fontWeight: 400 }

export const BreadCrumbs = ({ arr, homeUrl = '' }: { arr: { url: string, name: string }[], homeUrl?: string }) => {
    const history = useHistory()
    const data = useContext(ContextProvider)
    console.log(data)

    return (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <a style={{ ...linkStyle, color: '#68737D', cursor: 'pointer' }} onClick={() => history.push(homeUrl)}>Category</a>
            {
                arr.map((el) => (
                    <a key={el.url} style={{
                        cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={el.url === '#' ? () => {} : () => history.push(el.url)}>
                        <img src={breadCrumbsIcon} alt=""/>
                        <span style={{ ...linkStyle, marginLeft: '15px', color: el.url === '#' ? '#000000' : '#68737D' }}>{el.name}</span>
                    </a>
                ))
            }
        </div>
    )
}
import React, {useState} from "react";
import {Modal} from "../../../components/Modal/Modal";
import Input from "../../../components/UI/Input/Input";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {createTaskStatusService} from "../../../services/taskTypeService";
import {toastifySuccess} from "../../../components/toastify/toastify";

type Props = {
    close: () => void
    updateItems: () => void
}

export const CreateTaskTypeModal: React.FC<Props> = ({ close, updateItems }) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        name: '',
        color: '#2361FF'
    })

    const validationProduct = () => {
        const errors = controlErrorFunc({  obj: values, config: {
                name: { required: true, maxLength: 255 },
            }})
        if (errors) {
            setErrors(errors)
            return false
        } else setErrors({})

        createItem().then()
    }

    const createItem = async () => {
        setLoading(true)
        try {
            const { status, data } = await createTaskStatusService({
                color: values.color,
                name: values.name
            })
            if (status === 201 && data.id) {
                toastifySuccess('Item have been created')
                updateItems()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={'Create task type'} close={close} loading={loading}>
            <>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '85%' }}>
                        <Input
                            className={'mt15'}
                            label={'Name'}
                            otherProps={{
                                value: values.name,
                                onChange: event => setValues({ ...values, name: event.target.value })
                            }}
                            errorMessage={errors?.name} valid={!errors.name}
                        />
                    </div>
                    <div style={{ width: '15%' }}>
                        <input
                            value={values.color}
                            onChange={e => setValues({ ...values, color: e.target.value })}
                            style={{
                                width: '100%',
                                height: '44px',
                                marginTop: '15px',
                                border: 'none',
                                boxSizing: 'border-box'
                            }}
                            type="color"
                        />
                    </div>
                </div>
                <button onClick={validationProduct} className={'btn w100 mt15'}>Create</button>
            </>
        </Modal>
    )
}
import {customAxios} from "./axios";

type CreateTaskType = {
    title: string
    amount: number
    subtitle?: string
    body: string
    body_short: string
    stageId: number
    taskStatusId: number
}
type UpdateTaskType = {
    title: string
    amount?: number
    subtitle?: string
    body?: string
    stageId?: number
    taskStatusId: number
}

export const getAllTaskByStageService = (stageId: number) => customAxios.get(`task?stageId=${stageId}`)

export const getSingleTaskService = (id: string) => customAxios.get(`task/${id}`)

export const createTaskService = (values: CreateTaskType) => customAxios.post(`task`, values)

export const updateTaskService = (id: number, values: UpdateTaskType) => customAxios.put(`task/${id}`, values)

export const deleteTaskService = (id: number) => customAxios.delete(`task/${id}`)
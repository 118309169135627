import React, {useEffect, useState} from "react";
import Input from "../../../components/UI/Input/Input";
import {Modal} from "../../../components/Modal/Modal";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {createProductService, updateProductService} from "../../../services/productService";
import {toastifyError} from "../../../components/toastify/toastify";
import {getSingleTaskService} from "../../../services/taskService";
import {ProductType} from "../../../types/product.type";

const validationConfig = {
    name: { required: true, maxLength: 255 },
    slug: { required: true, maxLength: 255 }
}

type Props = {
    close: () => {}
    updateProducts: () => {}
    item?: ProductType
}

export const CreateProduct: React.FC<Props> = ({ close, updateProducts, item }) => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        name: '',
        slug: ''
    })

    useEffect(() => {
        if (item) {
            setValues({
                ...item
            })
        }
    }, [item])

    const validationProduct = () => {
        const errors = controlErrorFunc({  obj: values, config: validationConfig })
        if (errors) {
            setErrors(errors)
            return false
        } else setErrors({})

        createProduct().then()
    }

    const createProduct = async () => {
        setLoading(true)
        try {
            const { status, data, request } = item ? await updateProductService(item.id, values) : await createProductService(values)
            if (request && request.status == 422) {
                const requestResponse = JSON.parse(request.response)
                if (requestResponse.errors) {
                    setErrors(requestResponse.errors)
                }
            }

            if ((status === 200 || status === 201) && data.id) {
                updateProducts()
            } else {
                toastifyError('Something went wrong')
            }

        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={`${item ? 'Update ' : 'Create '}` + 'product'} close={close} loading={loading}>
            <>
                <Input
                    className={'mt15'}
                    label={'Name'}
                    otherProps={{
                        value: values.name,
                        onChange: event => setValues({ ...values, name: event.target.value })
                    }}
                    errorMessage={errors?.name} valid={!errors.name}
                />
                <Input
                    className={'mt15'}
                    label={'Slug'}
                    otherProps={{
                        value: values.slug,
                        onChange: event => setValues({ ...values, slug: event.target.value })
                    }}
                    errorMessage={errors?.slug} valid={!errors.slug}
                />
                <button onClick={validationProduct} className="btn mt15 w100">{!item ? 'Create' : 'Update'}</button>
            </>
        </Modal>
    )
}
import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
import './react-editor.scss'

type Props = {
  value: string
  onChange: (key: string) => void
  onKeyDown?: (key: React.KeyboardEvent) => void
  valid?: boolean
  errorMessage?: string,
  height?: number,
  disabled?: boolean
  className?: string
  placeholder?: string
  onInit?: () => void
}

const ReactEditor:React.FC<Props> = ({
  value, onChange, onKeyDown, valid, errorMessage, height= 300, disabled, className, placeholder, onInit
}) => {


    return  (
    <div className={`${className} react-editor`}>
        {/* @ts-ignore */}
        <Editor
            value={value}
            apiKey='ltncv51bpcokmuet6cgwju1lct3joea9pxyn12aqnuy7qy6t' // test@space-level.com
            init={{
                height: height,
                plugins: [
                    ' preview paste searchreplace autolink autosave save directionality visualblocks ' +
                    'visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking ' +
                    'anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons code'
                ],
                menubar: 'file edit view insert format tools table',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect ' +
                    '| alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak ' +
                    '| charmap emoticons | fullscreen  preview print | insertfile image media template link anchor codesample code',
                toolbar_sticky: false,
                language: 'en',
                placeholder: placeholder
            }}
            onEditorChange={onChange}
            disabled={disabled}
            onInit={onInit}

        />
      {!valid && <span className={'react-editor__error-message'}>{errorMessage || 'Validation error'}</span>}
    </div>
  )
}

export default ReactEditor

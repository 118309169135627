import React from "react";
import {Layout} from "../pages/client/Layout";
import {ServiceView} from "../pages/client/Service/ServiceView";
import {TaskView} from "../pages/client/Task/TaskView";
import {Auth} from "../pages/auth/Auth";
import {Home} from "../pages/client/Layout/Home";
import {NotFoundPage} from "../pages/client/Layout/404";
import {Users} from "../pages/admin/user/Users";
import {Product} from "../pages/admin/product/Product";
import {TaskTypeList} from "../pages/admin/taskType/taskTypeList";
import {ClientStageView} from "../pages/client/Stage/ClientStageView";

const commonRouter = [
    {
        path: '/404',
        Component: NotFoundPage,
        exact: true
    },
    {
        path: '/product/:slug',
        Component: ServiceView,
        exact: false
    },
    {
        path: '/stage/:id',
        Component: ClientStageView,
        exact: false
    },
    {
        path: "/task/:id",
        Component: TaskView,
        exact: false
    },
]

export const clientRouter = [
    ...commonRouter,
    {
        path: '/',
        Component: Home,
        exact: true
    },
]

export const authRouter = [
    {
        path: '/404',
        Component: NotFoundPage,
        exact: true
    },
    {
        path: "/",
        Component: () => <Auth />,
        exact: false
    },
]

export const adminRouter = [
    ...commonRouter,
    {
        path: "/",
        Component: Product,
        exact: true
    },
    {
        path: "/users",
        Component: Users,
        exact: true
    },
    {
        path: "/task-type",
        Component: TaskTypeList,
        exact: true
    },
];

/*
export const clientRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout><div>Client</div></Layout>
    },
    {
        path: "/task/:id",
        element: <Layout><TaskView /></Layout>,
    },
    {
        path: "/:slug",
        element: <Layout><ServiceView /></Layout>,
    },
]);

export const adminRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout>Admin</Layout>,
    },
]);

export const authRouter = createBrowserRouter([
    {
        path: "/",
        element: <Auth />,
    },
]);*/

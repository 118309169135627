import React, {useContext} from "react";
import {Header} from "./Header";
import './layout.scss'
import {Sidebar} from "./Sidebar";
import {ProgressBar} from "../../../components/ProgressBar/ProgressBar";
import {ContextProvider} from "../../../context";

export const Layout: React.FC<any> = ({ children }) => {
    const { user } = useContext(ContextProvider)

    return (
        <main className={'layout'}>
            <Header />
            {user?.role === "client" && <ProgressBar/>}
            <div className="layout__container">
                {/*<aside className={'layout__sidebar'}>
                    <Sidebar />
                </aside>*/}
                <div className={'layout__content'}>
                    {children}
                </div>
            </div>
        </main>
    )
}

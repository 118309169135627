import React, {useContext, useEffect, useState} from "react";
import Input from "../../../../components/UI/Input/Input";
import ReactEditor from "../../../../components/UI/ReactEditor";
import {TaskType} from "../task.type";
import {deleteTaskService, getSingleTaskService, updateTaskService} from "../../../../services/taskService";
import {Loader} from "../../../../components/loader/Loader";
import {TaskStatusType} from "../../../../types/taskStatus.type";
import {toastifyError, toastifySuccess} from "../../../../components/toastify/toastify";
import {useHistory} from "react-router-dom";
import {ContextProvider} from "../../../../context";
import Select from "../../../../components/UI/Select/Select";
import {controlErrorFunc} from "../../../../components/UI/ControlValid/controlErrorValid";

type Props = {
    id: number
    taskData: TaskType | null
    setTaskData: (data: any) => void
}

const validationConfig = {
    title: { required: true, maxLength: 255 },
    subtitle: {  maxLength: 255 },
    amount: { required: true, maxLength: 255, number: true },
    taskStatusId: { required: true }
}

export const TaskUpdate: React.FC<Props> = ({ id, taskData, setTaskData }) => {
    const { taskTypes } = useContext(ContextProvider)
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        title: '',
        body_short: '',
        subtitle: '',
        taskStatusId: +'',
        amount: '',
        body: ''
    })

    const getTaskData = async () => {
        setLoading(true)
        try {
            const { status, data, request } = await getSingleTaskService(id + '')
            setValues({ ...data })
        } catch (e) {

        }
        setLoading(false)
    }

    useEffect(() => {
        getTaskData().then()
    }, [id])

    const validationProduct = () => {
        const errors = controlErrorFunc({  obj: values, config: validationConfig })
        if (errors) {
            setErrors(errors)
            toastifyError('Validation error')
            return false
        } else setErrors({})

        updateTask().then()
    }

    const updateTask = async () => {
        setLoading(true)
        try {
            const { status, data, request } = await updateTaskService(id, {
                ...values,
                amount: Number(values.amount)
            })
            if (status === 200) {
                toastifySuccess('Task has been saved')
                setTaskData(data)
                await getTaskData()
            }
        } catch (e) {

        }
        setLoading(false)
    }

    const deleteTask = async () => {
        if (!window.confirm('Are you sure?')) return

        try {
            const { status } = await deleteTaskService(id)
            if (status === 204) {
                toastifySuccess('Task has been deleted')
                history.push(`/product/${taskData?.stage.product.slug}?stage_id=${taskData?.stageId}`)
            }
        } catch (e) {

        }
    }

    return loading ? <Loader /> : (
        <div className={'task_view'} style={{ background: '#FFFFFF', padding: '30px', borderRadius: '5px' }}>
            <div className={'w100'}>
                <div className="btn-block" style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <button onClick={() => history.push(`/task/${id}`)} className={'btn btn-nobg mr15'}>Back</button>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <button onClick={deleteTask} className={'btn btn-nobg mr15'}>Delete</button>
                        <button onClick={validationProduct} className={'btn'}>Save</button>
                    </div>
                </div>
                <Select
                    className={'mt15'}
                    label={'Task type'}
                    value={values.taskStatusId}
                    options={(taskTypes || [])?.map(el => ({ value: el.id, label: el.name }))}
                    errorMessage={errors?.taskStatusId} valid={!errors.taskStatusId}
                    onChange={(item) => setValues({ ...values, taskStatusId: item.value })}
                />
                <Input
                    className={'mt15'}
                    label={'Title'}
                    otherProps={{
                        value: values.title,
                        onChange: event => setValues({ ...values, title: event.target.value })
                    }}
                    errorMessage={errors?.title} valid={!errors.title}
                />
                <Input
                    className={'mt15'}
                    label={'Typeform id'}
                    otherProps={{
                        value: values.subtitle,
                        onChange: event => setValues({ ...values, subtitle: event.target.value })
                    }}
                    errorMessage={errors?.subtitle} valid={!errors.subtitle}
                />
                <Input
                    label={'Cost'}
                    className={'mt15'}
                    otherProps={{
                        type: 'text',
                        value: +values.amount,
                        onChange: event => {
                            setValues({ ...values, amount: event.target.value })
                        }
                    }}
                    errorMessage={errors?.amount} valid={!errors.amount}
                />
                <ReactEditor
                    height={300}
                    className={'mt15'}
                    value={values.body_short}
                    onChange={(body_short) => setValues({ ...values, body_short })}
                    errorMessage={errors?.body_short} valid={!errors.body_short}
                />
                <ReactEditor
                    height={600}
                    className={'mt15'}
                    value={values.body}
                    onChange={(body) => setValues({ ...values, body })}
                    errorMessage={errors?.body} valid={!errors.body}
                />
            </div>
        </div>
    )
}
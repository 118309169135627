import React, {createContext, useState} from "react";
import {UserType} from "./types/user.type";
import {ProductType} from "./types/product.type";
import {TaskStatusType} from "./types/taskStatus.type";

type ContextType = {
    user: UserType | null
    setUser: (key: UserType) => void
    product: ProductType | null
    setProduct: (key: ProductType) => void
    taskTypes: TaskStatusType[] | null
    setTaskTypes: (key: TaskStatusType[]) => void
    isOpenFaqModal: boolean
    setIsOpenFaqModal: (key: boolean) => void
    progress: number
    setProgress: (key: number) => void
}

export const ContextProvider = createContext<ContextType>({} as ContextType)

export const Context: React.FC<any> = ({ children }) => {
    const [user, setUser] = useState<UserType>(null)
    const [product, setProduct] = useState<ProductType>({} as ProductType)
    const [taskTypes, setTaskTypes] = useState<TaskStatusType[]>([])
    const [isOpenFaqModal, setIsOpenFaqModal] = useState(false)
    const [progress, setProgress] = useState(1)

    return (
        <ContextProvider.Provider  value={{
            user, setUser,
            product, setProduct,
            taskTypes, setTaskTypes,
            isOpenFaqModal, setIsOpenFaqModal,
            progress, setProgress
        }}>
            {children}
        </ContextProvider.Provider>
    )
}
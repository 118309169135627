import {ServiceHeader} from "./ServiceHeader/ServiceHeader";
import {ServiceBody} from "./ServiceBody/ServiceBody";
import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {customAxios} from "../../../services/axios";
import {ContextProvider} from "../../../context";
import {Loader} from "../../../components/loader/Loader";
import {ProductType} from "../../../types/product.type";
import './service.scss'
import oneBlackIcon from "../../../assets/images/numbers/1-black.svg"
import twoBlackIcon from "../../../assets/images/numbers/2-black.svg"
import threeBlackIcon from "../../../assets/images/numbers/3-black.svg"
import oneWhiteIcon from "../../../assets/images/numbers/1-white.svg"
import twoWhiteIcon from "../../../assets/images/numbers/2-white.svg"
import threeWhiteIcon from "../../../assets/images/numbers/3-white.svg"
import arrowToRightIcon from "../../../assets/images/white-arrow-right.svg"

const serviceItemIcons = (index: number) => {
    switch (index) {
        case 1: return {
            white: oneWhiteIcon,
            black: oneBlackIcon
        }
        case 2: return {
            white: twoWhiteIcon,
            black: twoBlackIcon
        }
        case 3: return {
            white: threeWhiteIcon,
            black: threeBlackIcon
        }
        default: return {
            white: threeWhiteIcon,
            black: threeBlackIcon
        }
    }
}

const ServiceItem: React.FC<any> = ({ id, index, title, description }) => {
    const history = useHistory()

    return (
        <div onClick={() => history.push(`/stage/${id}`)} className={'service-item'}>
            <div className="service-item__heading">
                <b>{title}</b>
                <img className={'service-item__heading-img-white'} src={serviceItemIcons(index).white} alt=""/>
                <img className={'service-item__heading-img-black'} src={serviceItemIcons(index).black} alt=""/>
            </div>
            <div className="service-item__body">
                <p>{description}</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <a className={'service-item__arrow'} href="">
                        <img src={arrowToRightIcon} alt=""/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export const ServiceView: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const [loading, setLoading] = useState(false)
    const { product, setProduct, user, setProgress } = useContext(ContextProvider)
    const match: { slug: string } = useParams()
    const { slug } = match

    const getProduct = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get<ProductType>(`product/${slug}`)
            if (status === 200 && data.id) {
                setProduct(data)
                /*if (data.stages[0] && !queryParams.get('stage_id')) {
                    history.push(`/product/${slug}?stage_id=${data.stages[0].id}`)
                }*/
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getProduct().then()
        setProgress(1)
        document.title = 'Choose a category'
    }, [slug])

    return loading || !product ? <Loader /> : (
        <>
            <div>
                {user?.role === 'admin' ? <div>
                        <ServiceHeader title={product.name} />
                        <ServiceBody
                            slug={product.slug}
                            stages={product.stages}
                            getProduct={getProduct}
                        />
                    </div> :
                    <div className={'service-list'}>
                        {
                            (product?.stages || []).map((el, index) => (
                                <ServiceItem
                                    id={el.id}
                                    index={index + 1}
                                    title={el.name}
                                    description={el.description}
                                />
                            ))
                        }
                    </div>}
            </div>
        </>
    )
}
import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {ContextProvider} from "../../../context";

export const Home: React.FC = () => {
    const { user } = useContext(ContextProvider)
    const history = useHistory()

    useEffect(() => {
        if (user?.role === 'client') {
            history.push('/product/' + user.user_product.product.slug)
        }
    }, [])

    return (
        <></>
    )
}
import React, {useEffect, useState} from "react";
import {customAxios} from "../../services/axios";
import LogoIcon from '../../assets/images/logo.svg'
import './login.scss'
import Input from "../../components/UI/Input/Input";
import {toastifyError} from "../../components/toastify/toastify";
import {controlErrorFunc} from "../../components/UI/ControlValid/controlErrorValid";
import {useHistory} from "react-router-dom";
import { PopupButton } from '@typeform/embed-react'

export const Auth: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    const validationForm = async () => {
        const errors = controlErrorFunc({  obj: values, config: {
                email: { required: true, email: true, maxLength: 255 },
                password: { required: true, minLength: 6, maxLength: 255 }
            }
        })
        
        if (errors) {
            setErrors(errors)
            return false
        } else setErrors({})

        await auth()
    }

    const history = useHistory()
    useEffect(() => {
        history.push('/')
    }, [])

    const auth = async () => {
        try {
            const { status, data } = await customAxios.post(`user/login`, {
                email: values.email,
                password: values.password
            })

            if (status === 200 && data?.token) {
                localStorage.setItem('accessToken', data.token)
                window.location.reload()
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={'login-page'}>
            <div className={'login-page__form'}>
                <div className={'login-page__form-header'}>
                    <img src={LogoIcon} alt={''} />
                </div>
                <div className={'login-page__form-container'}>
                    <div className={'login-page__heading'}>
                        <h4>ACCOUNT</h4>
                        <h2>Sign in</h2>
                    </div>
                    <Input
                        className={'login-page__input'}
                        // label={'Email'}
                        errorMessage={errors?.email} valid={!errors.email}
                        otherProps={{
                            placeholder: 'Email',
                            value: values.email,
                            onChange: event => setValues({ ...values, email: event.target.value }),
                            onKeyDown: event => event.keyCode === 13 && validationForm()
                        }}
                    />
                    <Input
                        className={'mt10 login-page__input'}
                        // label={'Password'}
                        errorMessage={errors?.password} valid={!errors.password}
                        otherProps={{
                            type: 'password',
                            placeholder: 'Password',
                            value: values.password,
                            onChange: event => setValues({ ...values, password: event.target.value }),
                            onKeyDown: event => event.keyCode === 13 && validationForm()
                        }}
                    />
                    <button
                        disabled={!values.email || !values.password}
                        style={{ marginTop: '25px' }} className={'login-page__btn'} onClick={validationForm}>SIGN IN</button>
                </div>
                <div className={'login-page__form-footer'}>
                    <span>{'© Legal Nodes '}{(new Date()).getFullYear()}</span>
                </div>
            </div>
            <div className={'login-page__banner'}>
                <div>
                    <h1>Sign in to start solving your Web3 legal questions</h1>
                    {/*<a href={'/'} className={'login-page__read-more'}>
                        {t('auth.readMore')}
                        <img className={'login-page__arrow'} src={blackArrow} alt={'arrow'} />
                      </a>*/}
                </div>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {DataList} from "../../../components/List/DataList";
import {UserType} from "../../../types/user.type";
import {deleteUserService, getAllUsersService} from "../../../services/userService";
import {ProductType} from "../../../types/product.type";
import {useHistory} from "react-router-dom";
import {Loader} from "../../../components/loader/Loader";
import {deleteProductService} from "../../../services/productService";
import {toastifyError, toastifySuccess} from "../../../components/toastify/toastify";
import {CreateUser} from "./CreateUser";

export const Users: React.FC = () => {
    const history = useHistory()
    const [productModal, setProductModal] = useState<{ type?: string, item?: UserType }>({})
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState<UserType[]>([])

    const getAllUsers = async () => {
        setLoading(true)
        try {
            const { status, data } = await getAllUsersService()
            if (status === 200) {
                setUsers(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllUsers().then()
    }, [])

    const deleteUser = async (id: number) => {
        if (!window.confirm('Delete product?')) {
            return false
        }

        setLoading(true)
        try {
            const { status } = await deleteUserService(id)
            if (status === 204) {
                toastifySuccess('User has been deleted')
                await getAllUsers()
            } else {
                toastifyError('Something went wrong')
            }
        } catch (e) {}
        setLoading(false)
    }

    const Item: React.FC<{ item: UserType }> = ({ item }) => {
        if (!item) return <></>

        return (
            <>
                <td style={{ width: '100px' }}>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                <td>{item.user_product?.product?.name}</td>
            </>
        )
    }

    return loading ? <Loader /> :  (
        <div style={{ background: '#fff', padding: '25px', borderRadius: '10px' }}>
            <button onClick={() => setProductModal({ type: 'create' })} className={'btn mb15'}>Create user</button>
            <DataList
                th={['name', 'email', 'role', 'product']}
                list={users}
                Item={(item) => <Item item={item} />}
                // onUpdate={(item: UserType) => setProductModal({ type: 'update', item: item })}
                onDelete={(item: ProductType) => deleteUser(item.id)}
            />
            {productModal.type && <CreateUser
                item={productModal.item}
                close={() => setProductModal({})}
                updateUsers={getAllUsers} />
            }
        </div>
    )
}
import React from 'react';
import ReactDOM from 'react-dom'
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {MainRouter} from "./router/MainRouter";
import {Context} from "./context";
import {BrowserRouter} from "react-router-dom";


ReactDOM.render(
  <>
      <Context>
          <BrowserRouter>
              <MainRouter />
          </BrowserRouter>
      </Context>
  </>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import './modal.scss'
import {Loader} from "../loader/Loader";

type Props = {
    children: JSX.Element
    container?: string
    title?: string
    close: () => void
    loading?: boolean
    isHideHeader?: boolean
}

export const Modal: React.FC<Props> = ({
    children ,
    container = 'modal__minimal-container',
    title,
    close,
    loading,
    isHideHeader
}) => {
    return (
        <div className={'modal'}>
            <div className={container}>
                {loading ? <Loader /> : <>
                    {!isHideHeader && <div className="modal__header">
                        <div>
                            {title &&
                                <h1 className={'modal__header-title'}>{title}</h1>}
                        </div>
                        <div className="modal__header-close">
                            <div onClick={close}>&#10005;</div>
                        </div>
                    </div>}
                    {children}
                </>}
            </div>
        </div>
    )
}
import {customAxios} from "./axios";

type CreateStageType = { name: string, description: string, productId: number }
type UpdateStageType = { name: string, description?: string, productId?: number }

export const getAllStagesByProductService = (productId: number) => customAxios.get(`stage?productId=${productId}`)

export const getSingleStageService = (id: number) => customAxios.get(`stage/${id}`)

export const createStageService = (values: CreateStageType) => customAxios.post(`stage`, values)

export const updateStageService = (id: number, values: UpdateStageType) => customAxios.put(`stage/${id}`, values)

export const deleteStageService = (id: number) => customAxios.delete(`stage/${id}`)
import React, {useContext, useState} from "react";
import {ContextProvider} from "../../../../context";
import {createTaskService} from "../../../../services/taskService";
import {controlErrorFunc} from "../../../../components/UI/ControlValid/controlErrorValid";
import {useHistory} from "react-router-dom";
import {toastifyError, toastifySuccess} from "../../../../components/toastify/toastify";
import {ProductType} from "../../../../types/product.type";
import {Modal} from "../../../../components/Modal/Modal";
import Input from "../../../../components/UI/Input/Input";
import Select from "../../../../components/UI/Select/Select";

type Props = {
    stageId: number
    close: () => void
    getProduct: () => void
}

const validationConfig = {
    title: { required: true, maxLength: 255 },
    subtitle: {  maxLength: 255 },
    amount: { required: true, maxLength: 255, number: true },
    taskStatusId: { required: true }
}

export const CreateTask: React.FC<Props> = ({ stageId, close, getProduct }) => {
    const history = useHistory()
    const { product, setProduct, taskTypes } = useContext(ContextProvider)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        taskStatusId: +'',
        title: '',
        subtitle: '',
        amount: +''
    })

    const validationProduct = () => {
        const errors = controlErrorFunc({  obj: values, config: validationConfig })
        if (errors) {
            setErrors(errors)
            return false
        } else setErrors({})

        createTask().then()
    }

    const createTask = async () => {
        try {
            const { status, data, request } = await createTaskService({ ...values, body: '<p></p>', body_short: '<p></p>', stageId: stageId  })
            if (request && request.status == 422) {
                const requestResponse = JSON.parse(request.response)
                if (requestResponse.errors) {
                    setErrors(requestResponse.errors)
                }
            }

            if (status === 201) {
                toastifySuccess('Task has been created')
                getProduct()
                close()
            } else {
                toastifyError('Something went wrong')
            }
        } catch (e) {}
    }

    return (
        <Modal title={'Create task'} close={close} loading={loading}>
            <>
                <Select
                    className={'mt15'}
                    label={'Task type'}
                    value={values.taskStatusId}
                    options={(taskTypes || [])?.map(el => ({ value: el.id, label: el.name }))}
                    errorMessage={errors?.taskStatusId} valid={!errors.taskStatusId}
                    onChange={(item) => setValues({ ...values, taskStatusId: item.value })}
                />
                <Input
                    className={'mt15'}
                    label={'Task name'}
                    otherProps={{
                        value: values.title,
                        onChange: event => setValues({ ...values, title: event.target.value })
                    }}
                    errorMessage={errors?.title} valid={!errors.title}
                />
                <Input
                    className={'mt15'}
                    label={'Subtitle'}
                    otherProps={{
                        value: values.subtitle,
                        onChange: event => setValues({ ...values, subtitle: event.target.value })
                    }}
                    errorMessage={errors?.subtitle} valid={!errors.subtitle}
                />
                <Input
                    className={'mt15'}
                    label={'Cost'}
                    otherProps={{
                        type: 'number',
                        value: values.amount,
                        onChange: event => setValues({ ...values, amount: +event.target.value })
                    }}
                    errorMessage={errors?.amount} valid={!errors.amount}
                />
                <button onClick={validationProduct} className="btn mt15 w100">Create</button>
            </>
        </Modal>
    )
}
import React, {useContext, useEffect, useState} from "react";
import './stageView.scss'
import arrowIcon from '../../../assets/images/blackArrowToRight.svg'
import {Link, useHistory} from "react-router-dom";
import {StageType} from "../../../types/stage.type";
import {TaskStatusType} from "../../../types/taskStatus.type";
import {ContextProvider} from "../../../context";
import {CreateTask} from "../Task/components/CreateTask";
import {deleteStageService} from "../../../services/stageService";

type StageViewProps = { id: string, stage: StageType | undefined, onUpdate: () => void, getProduct: () => void }

type TaskItemProps = {
    id: number
    taskStatus: TaskStatusType
    title: string
    subtitle?: string
    className: string
}

const TaskItem: React.FC<TaskItemProps> = ({ taskStatus, title, subtitle, id, className }) => {
    return (
        <div style={{ borderLeftColor: taskStatus?.color }} className={'task-item'}>
            <div className={'task-item__center'}>
                <div className="task-item__description">
                    <h4>{title}</h4>
                    {subtitle && <p>{subtitle}</p>}
                </div>
            </div>
            <div className="task-item__action">
                <Link to={`/task/${id}`} className={"task-item__button " + className}>
                    VIEW
                    <div className="task-item__squire">
                        <img src={arrowIcon} alt=""/>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export const StageView: React.FC<StageViewProps> = ({ id, stage, onUpdate, getProduct }) => {
    const history = useHistory()
    const [stageModal, setStageModal] = useState(false)
    const { user, product } = useContext(ContextProvider)

    const deleteStage = async () => {
        if (!window.confirm('Are you sure?')) return

        try {
            const { status } = await deleteStageService(+id)
            if (status === 204) {
                getProduct()
                history.push(`/product/${product?.slug}`)
            }
        } catch (e) {}
    }

    return !stage ? <>Loading</> : (
        <div className={'stage_view'}>
            <div className="stage_view__description">
                <h2>{stage.name}</h2>
                <p>
                    {stage.description}
                </p>
                <div style={{ display: "flex" }}>
                    {user?.role === "admin" && <button
                        style={{ background: '#000000', border: 'none' }}
                        onClick={onUpdate} className={'btn mt10'}>
                        Update stage
                    </button>}
                    {user?.role === "admin" && <button
                        style={{ background: '#EA033A', border: 'none', marginLeft: '10px' }}
                        onClick={deleteStage} className={'btn mt10'}>
                        Delete stage
                    </button>}
                </div>
            </div>
            <div className="stage_view__tasks">
                {
                    (stage.tasks || []).map((item, index) => (
                        <TaskItem
                            key={item.id}
                            id={item.id}
                            taskStatus={item.task_status as TaskStatusType}
                            title={item.title}
                            subtitle={item.subtitle}
                            className={'task-item-' + index}
                        />
                    ))
                }

                {user?.role === 'admin' && <div onClick={() => setStageModal(true)} style={{ background: '#000000', border: 'none', cursor: 'pointer' }} className={'task-item'}>
                    <div className={'task-item__center'}>
                        <div className="task-item__description">
                            <h4 style={{ color: '#FFFFFF', fontSize: '13px', textTransform: 'uppercase' }}>Create Task</h4>
                        </div>
                    </div>
                    <div className="task-item__action" />
                </div>}
            </div>
            {stageModal && <CreateTask
                stageId={+id}
                close={() => setStageModal(false)}
                getProduct={getProduct}
            />}
        </div>
    )
}
import {customAxios} from "./axios";

type CreateUserType = {
    name: string
    role: string
    email: string
    password: string
    productId: number
}

type UpdateUserType = {
    name?: string
    role?: string
    email?: string
    password?: string
    productId?: number
    loggedCount?: number
}

export const getAllUsersService = () => customAxios.get(`user`)

export const userProfileService = () => customAxios.get('user/profile')

export const loginUserService = ({ email, password }: { email: string, password: string }) => customAxios.post(`user/login`, { email, password })

export const getUserById = (id: number) => customAxios.get(`user/${id}`)

export const createUserService = (values: CreateUserType) => customAxios.post(`user`, values)

export const updateUserService = (id: number, values: UpdateUserType) => customAxios.put(`user/${id}`, values)

export const deleteUserService = (id: number) => customAxios.delete(`user/${id}`)
import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../context";
import {ToastContainer} from "react-toastify";
import {customAxios} from "../services/axios";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import {adminRouter, authRouter, clientRouter} from "./clientRoutes";
import {Loader} from "../components/loader/Loader";
import {Auth} from "../pages/auth/Auth";
import {Layout} from "../pages/client/Layout";
import {NotFoundPage} from "../pages/client/Layout/404";
import {ErrorBoundaryComponent} from "../components/ErrorBoundry/ErrorBoundaryComponent";
import ErrorBoundary from "../components/ErrorBoundry/ErrorBoundry";
import {updateUserService, userProfileService} from "../services/userService";
import {getAllTaskStatusesService} from "../services/taskTypeService";
import {FaqModal} from "../components/FaqModal/FaqModal";
import {WelcomeModal} from "../components/WelcomeModal/WelcomeModal";
import Joyride from "react-joyride";

export const MainRouter: React.FC = (props) => {
    const [isWelcomeModal, setIsWelcomeModal] = useState(false)
    const [loading , setLoading] = useState(false)
    const { user, setUser, setTaskTypes, isOpenFaqModal } = useContext(ContextProvider)
    const history = useHistory()
    const location = useLocation()
    const [run, setRun] = useState(false)

    const switchRouter = () => {
        if(user?.role === 'admin') {
            return adminRouter
        } else if (user?.role === 'client') {
            return clientRouter
        } else return [{
            path: '/',
            exact: false,
            Component: NotFoundPage,
        }]
    }

    const userProfile = async () => {
        setLoading(true)
        const { status, data } = await userProfileService()
        const { status: taskStatus, data: taskData } = await getAllTaskStatusesService()
        if (status === 200 && data.id) {
            setUser(data)
        }

        if (taskStatus === 200 && taskData?.items) {
            setTaskTypes(taskData.items)
        }
        setLoading(false)
    }

    const startWelcomeModule = () => {
        setTimeout(() => setRun(true), 300)
        setTimeout(() => {
            const link = document.getElementsByClassName('react-joyride__beacon');
            for (let i = 0; i < 1; i++) {
                // @ts-ignore
                if (link[i]) link[i].click()
            }
        }, 600)
    }

    const updateUserLoggedCount = async () => {
        if (user)
            await updateUserService(user.id, { loggedCount: 1, name: user.name })
    }

    useEffect(() => {
        if (localStorage.getItem('accessToken') && !user) {
            userProfile().then()
        }

        if (localStorage.getItem('accessToken') && user && user.role === "client" && user.loggedCount === 0) {
            setIsWelcomeModal(true)
            updateUserLoggedCount().then()
        }
    }, [user])

    if (!localStorage.getItem('accessToken')) {
        return <>
            <Auth />
            <ToastContainer />
        </>
    }
    return loading ? <div style={{ height: '100vh' }}><Loader /></div> : <Layout>
        {/*<TransitionGroup className={'transition-group-screen'}>
            <CSSTransition
                // @ts-ignore
                key={location.key}
                timeout={{ enter: 500, exit: 0 }}
                classNames={'slide'}
            >*/}
            <ErrorBoundary>
                <Switch>
                    {
                        switchRouter().map(({ path, Component, exact }) => (
                            <Route
                                key={path}
                                exact={exact}
                                path={path}
                                component={Component}
                            />
                        ))
                    }
                    <Route path={'*'} exact component={() => <ErrorBoundaryComponent type={404} />} />
                </Switch>
            </ErrorBoundary>
            {/*</CSSTransition>
        </TransitionGroup>*/}
        <ToastContainer />
        {/*{isWelcomeModal && <WelcomeModal close={() => {
            setIsWelcomeModal(false)
            //startWelcomeModule()
        }} />}*/}
        {isOpenFaqModal && <FaqModal />}
        <Joyride
            // showProgress={true}
            disableCloseOnEsc={true}
            scrollToFirstStep={true}
            continuous
            hideCloseButton
            run={run}
            showSkipButton={false}
            hideBackButton={true}
            locale={{
                last: 'DONE'
            }}
            steps={[
                {
                    title: 'Step 1',
                    target: '.service_body__stage-item-active',
                    content: 'Click on a project stage to learn more about it.',
                    placement: 'bottom'
                },
                {
                    target: '.stage_view__tasks',
                    content: 'These are the different legal tasks that a Web3 project may require at this stage.',
                    placement: 'left'
                },
                {
                    target: '.task-item-0',
                    content: <div>Click “View” to learn more about each legal task.<br />
                        You can open an account with Legal Nodes at any time by entering any legal task and clicking “Get Started”.</div>,
                    placement: 'bottom',
                }
            ]}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
        />
    </Layout>
}
import {useContext} from "react";
import {ContextProvider} from "../../context";
import "./progress-bar.scss"

export const ProgressBar = () => {
    const { progress } = useContext(ContextProvider)

    return (
        <div className={'progress-bar'}>
            <div className="progress-bar__top">
                <b className={progress === 1 ? 'progress-bar__top-active' : ''}>CHoOSE a CATEGORY</b>
                <b className={progress === 2 ? 'progress-bar__top-active' : ''}>CHOoSE a QUESTION</b>
                <b className={progress === 3 ? 'progress-bar__top-active' : ''}>Get YOUR ANSWER</b>
                <b style={{ display: 'none' }} className={'progress-bar__top-active'}>{progress}/3</b>
            </div>
            <div className="progress-bar__bottom">
                <div className="progress-bar__bottom-line" style={{ width: `${progress * 33.3}%` }} />
            </div>
        </div>
    )
}
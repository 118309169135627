import React, {useContext, useEffect, useState} from "react";
import "./serviceHeader.scss"
import {getAllTaskStatusesService} from "../../../../services/taskTypeService";
import {TaskStatusType} from "../../../../types/taskStatus.type";
import {ContextProvider} from "../../../../context";

type ServiceHeaderProps = {
    title: string
}

type ColorItemType = { color: string, name: string }

const ColorItem: React.FC<ColorItemType> = ({ color, name }) => {
    return (
        <div className={'service_header__colorItem'}>
            <div
                style={{ backgroundColor: color }}
                className={'service_header__colorItem-squire'}
            />
            <span className={'service_header__colorItem-label'}>{name}</span>
        </div>
    )
}

export const ServiceHeader: React.FC<ServiceHeaderProps> = ({ title }) => {
    const { taskTypes } = useContext(ContextProvider)

    console.log('taskTypes', taskTypes)

    return (
        <div className={'service_header'}>
            <h1 className={'service_header__heading'}>{title}</h1>
            <div className="service_header__color">
                {
                    (taskTypes || []).map(item => <ColorItem key={item.id} color={item.color} name={item.name} />)
                }
            </div>
        </div>
    )
}
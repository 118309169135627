import React, {useState} from 'react'
import Select from 'react-select'
import './select.scss'

type SelectItem = {
  value: string | number,
  label: string | number
}

type SelectType = {
  label?: string;
  value: string | number;
  options: SelectItem[];
  valid?: boolean;
  className?: string;
  errorMessage?: string;
  onChange?: (key:any, actionMeta: any) => void;
  isMulti?: boolean;
  onInputChange?: (text: string) => void;
  inputValue?: string,
  disabled?: boolean,
  styles?: any
}

const ReactSelect:React.FC<SelectType> = ({
  label,
  value,
  options,
  valid = true,
  className,
  errorMessage,
  onChange,
  isMulti,
  onInputChange,
  inputValue,
  disabled,
  styles
}) => {
  const selected = options.find((el) => String(el.value) === String(value))
  const [search, setSearch] = useState('');

  const selectedArray = (val:string) => {
    if (val) {
      return val.split(',').map((el) => (
        options.find((option) => String(option.value) === String(el))
      ))
    }
  }

  return (
    <div className={`${className} react-select`}>
      {label && <label className={'react-select__label'}>{label}</label>}
      <Select
        value={isMulti ? selectedArray(`${value}`) : selected}
        options={options.filter(({ label }) => String(label).toLowerCase().includes(search.toLowerCase()))}
        className={'react-select__container'}
        classNamePrefix={'react-select'}
        onChange={onChange}
        isMulti={isMulti}
        onInputChange={(text) => setSearch(text)}
        inputValue={inputValue}
        isDisabled={disabled}
        styles={styles}
      />
      {!valid && <span className={'input__error-message'}>{errorMessage}</span>}
    </div>
  )
}

export default ReactSelect

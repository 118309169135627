import './table.scss'
import React from "react";
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/delete.svg'
import arrowIcon from '../../assets/images/arrowToRight.svg'

type Props = {
    th: string[]
    list: unknown[]
    Item: React.FC<any>
    onDelete?: (item: any) => void
    onUpdate?: (item: any) => void
    link?: (item: any) => void
}

export const DataList: React.FC<Props> = ({
    th,
    list,
    Item,
    onDelete,
    onUpdate,
    link
}) => {
    return (
        <>
            <table className={'data-table'}>
                <tr>
                    {
                        th.map(item => <th key={item}>{item}</th>)
                    }
                    {onUpdate && <th style={{ width: '30px' }} />}
                    {onDelete && <th style={{ width: '30px' }} />}
                    {link && <th style={{ width: '30px' }} />}
                </tr>
                {
                    (list || []).map((item: any) => (
                        <tr>
                            <Item { ...item } />
                            {onDelete && list.length > 1 && <td><img onClick={() => onDelete(item)} src={deleteIcon} alt=""/></td>}
                            {onUpdate && <td><img onClick={() => onUpdate(item)} src={editIcon} alt=""/></td>}
                            {link && <td><img onClick={() => link(item)} src={arrowIcon} alt=""/></td>}
                        </tr>
                    ))
                }
            </table>
        </>
    )
}
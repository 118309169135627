import {customAxios} from "./axios";

type CreateProductType = { name: string, slug: string }
type UpdateProductType = { name: string, slug?: string }

export const getAllProductsService = () => customAxios.get(`product`)

export const getSingleProductService = (slug: string) => customAxios.get(`product/${slug}`)

export const createProductService = (values: CreateProductType) => customAxios.post(`product`, values)

export const updateProductService = (id: number, values: UpdateProductType) => customAxios.put(`product/${id}`, values)

export const deleteProductService = (id: number) => customAxios.delete(`product/${id}`)
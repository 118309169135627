import React, {useContext, useEffect, useState} from "react";
// import {useSearchParams, useHref, useParams} from 'react-router-dom'
import './serviceBody.scss'
import {StageView} from "../../Stage/StageView";
import {useHistory, useLocation} from 'react-router-dom'
import {ContextProvider} from "../../../../context";
import {CreateStage} from "../../Stage/CreateStage";
import {StageType} from "../../../../types/stage.type";

type ServiceBodyType = {
    slug: string
    stages: any[]
    getProduct: () => void
}


const stages = [
    { id: 1, name: 'DEV LAB COMPANY SETUP' },
    { id: 2, name: 'WEB3 PRODUCT LAUNCH' },
    { id: 3, name: 'TOKEN ISSUANCE AND DISTRIBUTION' },
    { id: 4, name: 'DAO LAUNCH' },
]

export const ServiceBody: React.FC<ServiceBodyType> = ({ stages, slug, getProduct }) => {
    const [stageModal, setStageModal] = useState<{ type?: string, item?: StageType }>({})
    const { product, user } = useContext(ContextProvider)
    const location = useLocation()
    const history = useHistory()
    const queryParams = new URLSearchParams(location.search)

    return !product && !queryParams.get('stage_id') ? <>Loading</> : (
        <div className={'service_body'}>
            <div className="service_body__stages">
                {
                    (stages || []).map((item, index) => (
                        <div
                            key={index}
                            onClick={() => history.push(`/product/${slug}?stage_id=${item?.id}`)}
                            className={'service_body__stage-item ' + `${String(item.id) === queryParams.get('stage_id') && 'service_body__stage-item-active'}`}
                        >
                            <b>STAGE {index + 1}</b>
                            <span>{item.name}</span>
                        </div>
                    ))
                }
                {user?.role === "admin" && <div
                    style={{ backgroundColor: '#000000' }}
                    onClick={() => setStageModal({ type: 'create' })}
                    className={'service_body__stage-item'}
                >
                    <b style={{ color: '#fff' }}>Create</b>
                    <span style={{ color: '#fff' }}>new STAGE</span>
                </div>}
            </div>
            <div className="service_body__stage-body">
                {queryParams.get('stage_id') && <StageView
                    stage={(product?.stages || []).find(el => el.id === +(queryParams.get('stage_id') || 1))}
                    id={queryParams.get('stage_id') || ''}
                    onUpdate={() => setStageModal({ type: 'update', item: (product?.stages || []).find(el => el.id === +(queryParams.get('stage_id') || 1)) })}
                    getProduct={getProduct}
                />}
            </div>
            {stageModal.type && <CreateStage
                close={async () => setStageModal({})}
                item={stageModal.item}
                getProduct={getProduct}
            />
            }
        </div>
    )
}
import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../../../context";
import arrowIcon from "../../../assets/images/white-arrow-right.svg"
import "./client-stage.scss"
import {useHistory, useParams} from "react-router-dom";
import {Loader} from "../../../components/loader/Loader";
import {StageType} from "../../../types/stage.type";
import {TaskType} from "../../../types/task.type";
import {customAxios} from "../../../services/axios";
import {BreadCrumbs} from "../../../components/BreadCrumbs/BreadCrumbs";
import { PopupButton } from '@typeform/embed-react'

const TaskItem = ({ id, title }: { id: number, title: string }) => {
    return (
        <a href={`https://form.typeform.com/to/YmGRTVpf#id=${id}`} className={'client-stage__task-item'}>
            <p>{title}</p>
            <button>
                <img src={arrowIcon} alt=""/>
            </button>
        </a>
    )
}

export const ClientStageView = () => {
    const { id }: { id: string } = useParams()
    const { product, setProgress } = useContext(ContextProvider)
    const [stage, setStage] = useState<any>(null)

    const getProductData = async () => {
        const { status, data } = await customAxios.get(`stage/${id}`)
        if (status === 200) {
            setStage(data)
            document.title = data?.name || 'Stage data'

        }
    }

    useEffect(() => {
        getProductData().then()
        setProgress(2)
    }, [product])

    return !stage ? <Loader /> : (
        <div>
            <div className={'client-stage__bread-crumbs'}>
                <BreadCrumbs
                    homeUrl={'/product/' + stage?.product?.slug}
                    arr={[ { url: `#`, name: stage?.name } ]}
                />
            </div>
            <div className={'client-stage'}>
                <div className="client-stage__left">
                    <div className="client-stage__left-header">
                        {stage.name}
                    </div>
                    <div className={'client-stage__left-body'}>
                        <h2>Choose a question</h2>
                        <p>{stage.description}</p>
                    </div>
                    <div className="client-stage__task-list">
                        {
                            (stage?.tasks.filter((el: any) => el.subtitle) || []).map((el: TaskType) => (
                                <TaskItem key={el?.id} id={el?.id} title={el?.title} />
                            ))
                        }
                    </div>
                </div>
                <div className="client-stage__right">
                    <div className="client-stage__right-body">
                        <h2>Can't find the right<br /> question?</h2>
                        <p>
                            If can’t find what you’re looking for or need help choosing the right question,
                            send us a message and we’ll be in touch.
                        </p>
                    </div>
                    <div className="client-stage__right-footer">
                        <PopupButton id="YMIkpQAr" style={{ border: 0, padding: 0, background: 'none' }}>
                            <div className={'client-stage__right-footer-link'}>
                                <span>CONTACT US</span>
                                <div className={'client-stage__right-arrow'}>
                                    <img src={arrowIcon} alt=""/>
                                </div>
                            </div>
                        </PopupButton>
                    </div>
                </div>
            </div>
        </div>
    )
}
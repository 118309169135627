import axios, { AxiosInstance } from 'axios'
import {toastifyError} from "../components/toastify/toastify";
import 'react-toastify/dist/ReactToastify.css'
import {isArray} from "util";

const baseUrl = process.env.REACT_APP_BASE_API

export const customAxios: AxiosInstance = axios.create({
    baseURL: `${baseUrl}/`,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
})

customAxios.interceptors.response.use((response: any) => {
    return response
}, error => {
    if (error?.response) {
        if (error.response.status === 401) {
            localStorage.removeItem('accessToken')
            window.location.href = '/'
        } else if (error.response.status === 422) {
            if (error.response.data?.errors) {
                Object.keys(error.response.data.errors).map(key => {
                    toastifyError(`${key}: ${error.response.data?.errors[key]}`)
                })
            } else if (error.response.data.message) {
                toastifyError(error.response.data.message)
            } else {
                toastifyError('Validation error')
            }
        } else if (error.response.status === 500) {
            if (error?.response?.data) {
                if (error.response.data.error) {
                    if (error.response.data.error.errors && error.response.data.error.errors[0]) {
                        toastifyError(error.response.data.error.errors[0].message)
                    }
                } else {
                    toastifyError('Something went wrong')
                }
            } else {
                toastifyError('Something went wrong')
            }
        }
    }
    return error
})

import React, {useEffect, useState} from "react";
import {DataList} from "../../../components/List/DataList";
import {ProductType} from "../../../types/product.type";
import {useHistory} from "react-router-dom";
import {deleteProductService, getAllProductsService} from "../../../services/productService";
import {toastifyError, toastifySuccess} from "../../../components/toastify/toastify";
import {Loader} from "../../../components/loader/Loader";
import {CreateProduct} from "./CreateProduct";

export const Product: React.FC = () => {
    const [productModal, setProductModal] = useState<{ type?: string, item?: ProductType }>({})
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState<ProductType[]>([])

    const getItems = async () => {
        setProductModal({ })
        setLoading(true)
        try {
            const { status, data } = await getAllProductsService()
            if (status === 200 && data) {
                setItems(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    const deleteProduct = async (id: number) => {
        if (!window.confirm('Delete product?')) {
            return false
        }

        setLoading(true)
        try {
            const { status } = await deleteProductService(id)
            if (status === 204) {
                toastifySuccess('Product has been deleted')
                await getItems()
            } else {
                toastifyError('Something went wrong')
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getItems().then()
    }, [])

    const Item: React.FC<{ item: ProductType }> = ({ item }) => {
        return (
            <>
                <td style={{ width: '100px' }}>{item.id}</td>
                <td>{item.name}</td>
            </>
        )
    }

  return loading ? <Loader /> : (
      <div style={{ background: '#fff', padding: '25px', borderRadius: '10px' }}>
          <button onClick={() => setProductModal({ type: 'create' })} className={'btn mb15'}>Create product</button>
          <DataList
              th={['id', 'name']}
              list={items}
              Item={(item) => <Item item={item} />}
              link={(item: ProductType) => history.push(`/product/${item.slug}`)}
              onUpdate={(item: ProductType) => setProductModal({ type: 'update', item: item })}
              onDelete={(item: ProductType) => deleteProduct(item.id)}
          />
          {productModal.type && <CreateProduct
              close={async () => setProductModal({})}
              updateProducts={getItems}
              item={productModal.item}
          />}
      </div>
  )
}
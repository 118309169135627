import {customAxios} from "./axios";

type CreateTaskStatusType = { name: string, color: string, order?: number }
type UpdateTaskStatusType = { name: string, color?: string, order?: number }

export const getAllTaskStatusesService = () => customAxios.get(`task-status`)

export const getSingleTaskStatusService = (slug: string) => customAxios.get(`task-status/${slug}`)

export const createTaskStatusService = (values: CreateTaskStatusType) => customAxios.post(`task-status`, values)

export const updateTaskStatusService = (id: number, values: UpdateTaskStatusType) => customAxios.put(`task-status/${id}`, values)

export const deleteTaskStatusService = (id: number) => customAxios.delete(`task-status/${id}`)
import logoImg from '../../../assets/images/logo.svg'
import './header.scss'
import React, {useContext} from "react";
import {ContextProvider} from "../../../context";
import {Link} from "react-router-dom";
import usersIcon from "../../../assets/images/users.svg";
// import { Outlet, Link } from "react-router-dom";

export const Header = () => {
    const { setIsOpenFaqModal, user } = useContext(ContextProvider)

    return (
        <header className={'header'}>
            <div className={'header__container'}>
                <div className="header__left">
                    <img src={logoImg} alt=""/>
                </div>
                <div className="header__right">
                    <div className="header__nav">
                        {/*<a style={{ cursor: 'pointer' }} onClick={() => setIsOpenFaqModal(true)}>FAQ</a>*/}
                        {user?.role === 'admin' && <Link to={'/users'}>Users</Link>}
                        {user?.role === 'admin' && <Link to={'/'}>Products</Link>}
                    </div>
                    <div className="header__actions">
                        <a onClick={() => {
                            localStorage.clear()
                            window.location.href = '/'
                        }} className={'btn'}>
                            Sign out
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
}
import React, {useContext, useEffect, useState} from "react";
import {Modal} from "../../../components/Modal/Modal";
import Input from "../../../components/UI/Input/Input";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError, toastifySuccess} from "../../../components/toastify/toastify";
import {createStageService, updateStageService} from "../../../services/stageService";
import {ContextProvider} from "../../../context";
import TextArea from "../../../components/UI/TextArea";
import {StageType} from "../../../types/stage.type";

type Props = {
    close: () => void
    getProduct: () => void
    item?: StageType
}

const validationConfig = {
    name: { required: true, maxLength: 255 },
    description: { required: true, maxLength: 1024 }
}

export const CreateStage: React.FC<Props> = ({ close, item, getProduct }) => {
    const { product, setProduct, user } = useContext(ContextProvider)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        name: '',
        description: ''
    })

    useEffect(() => {
        if (item) {
            setValues({
                name: item.name,
                description: item.description
            })
        }
    }, [item])

    const validationProduct = () => {
        const errors = controlErrorFunc({  obj: values, config: validationConfig })
        if (errors) {
            setErrors(errors)
            return false
        } else setErrors({})

        createProduct().then()
    }

    const createProduct = async () => {
        setLoading(true)

        if (!product) return

        const dataValues = { ...values, productId: product?.id }
        try {
            const { status, data, request } = item ? await updateStageService(item.id, values) : await createStageService(dataValues)
            if (request && request.status == 422) {
                const requestResponse = JSON.parse(request.response)
                if (requestResponse.errors) {
                    setErrors(requestResponse.errors)
                }
            }

            if ((status === 200 || status === 201) && data.id) {
                toastifySuccess('Stage has been updated')
                close()
                getProduct()
            } else {
                toastifyError('Something went wrong')
            }

        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={`${item ? 'Update ' : 'Create '}` + 'stage'} close={close} loading={loading}>
            <>
                <Input
                    className={'mt15'}
                    label={'Name'}
                    otherProps={{
                        value: values.name,
                        onChange: event => setValues({ ...values, name: event.target.value })
                    }}
                    errorMessage={errors?.name} valid={!errors.name}
                />
                <TextArea
                    className={'mt15'}
                    label={'Description'}
                    otherProps={{
                        value: values.description,
                        onChange: event => setValues({ ...values, description: event.target.value }),
                        rows: 10
                    }}
                    errorMessage={errors?.description} valid={!errors.description}
                />
                <button onClick={validationProduct} className="btn mt15 w100">{!item ? 'Create' : 'Update'}</button>
            </>
        </Modal>
    )
}
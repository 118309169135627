import {Modal} from "../Modal/Modal";
import {useContext, useState} from "react";
import {ContextProvider} from "../../context";
import './faq-modal.scss'
import personImage from '../../assets/images/faq-person.png'
import headingImage from '../../assets/images/faq-heading.png'
import hideIcon from '../../assets/images/faq-hide.svg'
import showIcon from '../../assets/images/faq-show.svg'
import closeIcon from '../../assets/images/close-icon.svg'

const data = [
    {
        title: 'I would like to have a follow-up call to discuss my legal needs in more detail.',
        description: "<p><a target='_blank' href='https://calendly.com/max-legalnodes/45min'>Book a call with an expert here.</a></p>"
    },
    {
        title: 'I need help choosing the best options for my startup’s needs.',
        description: "<p>We can help you choose the best options during a 1-hour Discovery Session.<p>" +
            "Book your Discovery Session <a href='https://calendly.com/max-legalnodes/45min' target='_blank'>here</a>." +
            "</p></p>"
    },
    {
        title: 'I want to receive price or time estimate for my Legal Roadmap.',
        description: "<p>" +
            "To receive estimates for your unique Legal Roadmap we invite you to have a Discovery Session first, so we can better understand your legal needs, and then allocate the best legal service providers to your legal tasks and provide you cost and time estimates." +
            "<p>Please <a href='https://calendly.com/max-legalnodes/45min' target='_blank'>book a 1-hour Discovery Session</a> to get started.</p>" +
            "</p>"
    },
    {
        title: 'Is everything in this Menu of Solutions going to be on my Legal Roadmap?',
        description: "<p>" +
            "Not necessarily. All the legal services outlined here highlight the possible steps that could be included in your Legal Roadmap. To build a bespoke Legal Roadmap for your project, start with a Discovery Session." +
            "<p>Book your <a href='https://calendly.com/max-legalnodes/45min' target='_blank'>1-hour Discovery Session here.</a></p>" +
            "</p>"
    },
    {
        title: 'What do I need to get started with onboarding?',
        description: "<p>" +
            "You can get started any time by <a href='https://cabinet.legalnodes.com/registration' target='_blank'>registering on our platform.</a>" +
            "</p>"
    }
]

export const FaqModal = () => {
    const { setIsOpenFaqModal } = useContext(ContextProvider)
    const [openedIndex, setOpenedIndex] = useState(0)

    return (
        <div className={'faq-modal'}>
            <Modal title={''} close={() => setIsOpenFaqModal(false)} container={'faq-modal'} isHideHeader={true}>
                <>
                    <div className="faq-modal__header">
                        <img className={'faq-modal__header-person'} src={personImage} alt=""/>
                        <img src={headingImage} alt=""/>
                        <img onClick={() => setIsOpenFaqModal(false)}
                            className={'faq-modal__header-close'} src={closeIcon} alt=""/>
                    </div>
                    <div className="faq-modal__body">
                        {
                            (data || []).map(({ title, description }, index) => (
                                <div className="faq-modal__item">
                                    <div className="faq-modal__item-text">
                                        <p className={'faq-modal__item-heading'}>
                                            {title}
                                        </p>
                                        {index === openedIndex && <p className={'faq-modal__item-body'}>
                                            <div dangerouslySetInnerHTML={{ __html: description }} />
                                        </p>}
                                    </div>
                                    <div className={'faq-modal__item-action'}>
                                        {
                                            index === openedIndex ?
                                                <img onClick={() => setOpenedIndex(-1)} src={showIcon} alt=""/> :
                                                <img onClick={() => setOpenedIndex(index)} src={hideIcon} alt=""/>
                                        }
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </>
            </Modal>
        </div>
    )
}
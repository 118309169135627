import React, {useContext, useEffect, useState} from "react";
import {ContextProvider} from "../../../context";
import {createTaskService} from "../../../services/taskService";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {useHistory} from "react-router-dom";
import {toastifyError, toastifySuccess} from "../../../components/toastify/toastify";
import {ProductType} from "../../../types/product.type";
import {Modal} from "../../../components/Modal/Modal";
import Input from "../../../components/UI/Input/Input";
import Select from "../../../components/UI/Select/Select";
import {UserType} from "../../../types/user.type";
import {getAllProductsService} from "../../../services/productService";
import {createUserService} from "../../../services/userService";

type Props = {
    item?: UserType
    close: () => void
    updateUsers: () => void
}

const validationConfig = {
    name: { required: true, maxLength: 255 },
    email: {  required: true, email: true, maxLength: 255 },
    password: { required: true, maxLength: 255, minLength: 6 },
    role: { required: true },
    productId: { required: true }
}

export const CreateUser: React.FC<Props> = ({ item, close, updateUsers }) => {
    const { product, setProduct, taskTypes } = useContext(ContextProvider)
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState<ProductType[]>([])
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        role: 'client',
        productId: ''
    })

    const getAllProducts = async () => {
        try {
            const { status, data } = await getAllProductsService()
            if (status === 200) {
                setProducts(data.items)
            }
        } catch (e) {}
    }

    useEffect(() => {
        console.log('item', item)
        getAllProducts().then()
    }, [])

    const validationProduct = () => {
        const errors = controlErrorFunc({  obj: values, config: validationConfig })
        if (errors) {
            setErrors(errors)
            return false
        } else setErrors({})

        createUser().then()
    }

    const createUser = async () => {
        try {
            const { status, data, request } = await createUserService({ ...values, password: values.password.trim(), productId: +values.productId  })
            if (request && request.status == 422) {
                const requestResponse = JSON.parse(request.response)
                if (requestResponse.errors) {
                    setErrors(requestResponse.errors)
                }
            }

            if (status === 201 && data) {
                toastifySuccess('User has been created')
                setTimeout(close, 200)
                updateUsers()
            }
        } catch (e) {}
    }

    return (
        <Modal title={'Create user'} close={close} loading={loading}>
            <>
                <Select
                    className={'mt15'}
                    label={'Accessed product'}
                    value={values.productId}
                    options={(products || [])?.map(el => ({ value: el.id, label: el.name }))}
                    errorMessage={errors?.productId} valid={!errors.productId}
                    onChange={(item) => setValues({ ...values, productId: item.value })}
                />
                <Select
                    className={'mt15'}
                    label={'Role'}
                    value={values.role}
                    options={[{ value: 'client', label: 'Client' }, { value: 'admin', label: 'Admin' }]}
                    errorMessage={errors?.role} valid={!errors.role}
                    onChange={(item) => setValues({ ...values, role: item.value })}
                />
                <Input
                    className={'mt15'}
                    label={'Name'}
                    otherProps={{
                        value: values.name,
                        onChange: event => setValues({ ...values, name: event.target.value })
                    }}
                    errorMessage={errors?.name} valid={!errors.name}
                />
                <Input
                    className={'mt15'}
                    label={'Email'}
                    otherProps={{
                        value: values.email,
                        onChange: event => setValues({ ...values, email: event.target.value })
                    }}
                    errorMessage={errors?.email} valid={!errors.email}
                />
                <Input
                    className={'mt15'}
                    label={'Password'}
                    otherProps={{
                        type: 'password',
                        value: values.password,
                        onChange: event => setValues({ ...values, password: event.target.value })
                    }}
                    errorMessage={errors?.password} valid={!errors.password}
                />
                <button onClick={validationProduct} className="btn mt15 w100">Create</button>
            </>
        </Modal>
    )
}
import React, {useContext, useEffect, useState} from "react";
import {TaskType} from "./task.type";
import {TaskLeftCol} from "./components/TaskHeader/TaskHeader";
import './taskView.scss'
import {TaskBody} from "./components/taskBody";
import {TaskRightCol} from "./components/TaskRightCol/TaskRightCol";
import {getSingleTaskService} from "../../../services/taskService";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {TaskStatusType} from "../../../types/taskStatus.type";
import {ContextProvider} from "../../../context";
import {TaskUpdate} from "./components/TaskUpdate";
import breadCrumbsIcon from '../../../assets/images/breadcrumbs-nav.png'
import {Loader} from "../../../components/loader/Loader";
import arrowIcon from "../../../assets/images/white-arrow-right.svg";
import saveArticle from "../../../assets/images/save-article.svg"
import articleNote from "../../../assets/images/article-note.svg"
import copyIcon from "../../../assets/images/copy.svg"
import { PopupButton } from '@typeform/embed-react'
import {BreadCrumbs} from "../../../components/BreadCrumbs/BreadCrumbs";
import {Modal} from "../../../components/Modal/Modal";

export const TaskView: React.FC = () => {
    const { user, setProgress } = useContext(ContextProvider)
    const location = useLocation()
    const history = useHistory()
    const { id }: { id: string } = useParams()
    const [taskData, setTaskData] = useState<TaskType>({} as TaskType)
    const [loading, setLoading] = useState(false)
    const [optionModal, setOptionModal] = useState(false)

    const getTaskData = async () => {
        setLoading(true)
        try {
            const { request, status, data } = await getSingleTaskService(id)
            if (request && request.status === 404) {
                history.push('/404')
            } else if (status === 200) {
                setTaskData(data)
                document.title = data?.title || 'Task data'
            }
        } catch (e) { console.log(e) }
        setLoading(false)
    }

    useEffect(() => {
        getTaskData().then()
        setProgress(3)
    }, [id])

    if (location.search === '?update=true' && user?.role === 'admin') {
        return <TaskUpdate
            id={+id}
            taskData={taskData}
            setTaskData={(data: TaskType) => setTaskData({ ...taskData, ...data})}
        />
    }

    return loading ? <Loader /> : (
        <>
            <div className={'task_view__breadcrumbs'} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <BreadCrumbs
                    homeUrl={'/product/' + taskData?.stage?.product?.slug}
                    arr={[ { url: `/stage/${taskData?.stage?.id}`, name: taskData?.stage?.name }, { url: '#', name: taskData?.title } ]}
                />
                {
                    user?.role === 'admin' && <a style={{ marginTop: '-30px' }} className={'btn'} href={'?update=true'}>Update</a>
                }
            </div>
            <div className={'task_view'}>
                <div className={'task_view__left'}>
                    <div className="task_view__category">
                        {taskData?.stage?.name}
                        <PopupButton id={`usMq8NMJ#id=${id}`} style={{ border: 'none', padding: 0 }}>
                            <div className={'task_view__heading-button'} style={{ display: 'none' }}>
                                <span>Save your answer</span>
                            </div>
                        </PopupButton>
                    </div>
                    <div className="task_view__heading">
                        <h1>{taskData?.title}</h1>
                        <PopupButton id={`usMq8NMJ#id=${id}`} style={{ border: 'none', padding: 0 }}>
                            <div className={'task_view__heading-button'}>
                                <span>Save your answer</span>
                                <img src={saveArticle} alt=""/>
                            </div>
                        </PopupButton>
                        {/*<div className={'task_view__heading-button'}>
                        <span>Save your answer</span>
                        <img src={saveArticle} alt=""/>
                    </div>*/}
                    </div>
                    <em className={'task_view__note'}>
                        This answer is not legal advice.
                    </em>
                    <div className="task_view__body">
                        <div className="task_view__body-container">
                            <div dangerouslySetInnerHTML={{ __html: taskData?.body_short }} />
                            <div className="task_view__notice">
                                <div className="task_view__notice-text">
                                    <h5>A note from Legal Nodes</h5>
                                    <p>
                                        This answer is generated using the information you've
                                        provided and our knowledge on the latest best practices in the industry.
                                    </p>
                                </div>
                                <img src={articleNote} alt=""/>
                            </div>
                            <div style={{ marginTop: '25px' }}>
                                <div dangerouslySetInnerHTML={{ __html: taskData?.body }} />
                            </div>
                            {/*<div className={'task_view__saft'}>
                                <div className="task_view__saft-icon">
                                    <img src={copyIcon} alt=""/>
                                </div>
                                <div className="task_view__saft-text">
                                    <span>SAFT template</span>
                                    <div className="task_view__saft-arrow">
                                        <img src={arrowIcon} alt=""/>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="task_view__your_question">
                                <h4>Did that answer your question?</h4>
                                <PopupButton id="usMq8NMJ"  className={'task_view__your_question-yes'}>
                                    YES
                                </PopupButton>
                                <button onClick={() => setOptionModal(true)} className={'task_view__your_question-no'}>NO</button>
                            </div>
                            <div className={'task_view__recalculate'}>
                                <a href={`https://form.typeform.com/to/YmGRTVpf#id=${taskData?.id}&skip_payment=1`}>
                                    <button>
                                        recalculate your answer
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'task_view__right'}>
                    <div className="task_view__right-body">
                        <h2>Take the next step on your<br /> legal structuring journey</h2>
                        <p>
                            You’ve discovered the first answer of many and you’ll need more
                            answers to understand how it all ties together. We can help
                            you build a full picture of all your legal structuring options.
                        </p>
                    </div>
                    <div className="task_view__right-footer">
                        <PopupButton id="WEtUIEof" style={{ border: 0, padding: 0, background: 'none' }}>
                            <div className={'task_view__right-footer-link'}>
                                <span>CONTACT US to continue</span>
                                <div className={'task_view__right-arrow'}>
                                    <img src={arrowIcon} alt=""/>
                                </div>
                            </div>
                        </PopupButton>
                    </div>
                </div>
            </div>

            {optionModal && <div className={'option-modal'}>
                <Modal close={() => setOptionModal(false)} title={'Ok, how can we help?'}>
                    <>
                        <a style={{ cursor: 'pointer' }} onClick={() => history.push(`/stage/${taskData?.stage?.id}`)} className={'option-modal__black'}>
                            <p>I have more questions</p>
                            {/*<em>(Go to other questions))</em>*/}
                        </a>
                        <PopupButton id="x2BbyvLG" style={{ border: 0, padding: 0, background: 'none', width: '100%', cursor: 'pointer' }}>
                            <a className={'option-modal__white'}>
                                <p>This answer isn`t relevant to me</p>
                                {/*<em>(Contact support)</em>*/}
                            </a>
                        </PopupButton>
                        <PopupButton id="TTFqXq5K" style={{ border: 0, padding: 0, background: 'none', width: '100%', cursor: 'pointer' }}>
                            <a className={'option-modal__white'}>
                                <p>I want to book a consultation with a Lawyer</p>
                            </a>
                        </PopupButton>
                    </>
                </Modal>
            </div>}
        </>
    )
}
import React from 'react'
import { InputType } from './Input.type'
import './input.scss'

const Input:React.FC<InputType> = ({
  label, className, errorMessage, otherProps, valid = true,
}) => {

  return (
    <div className={`input ${valid ? 'input__invalid' : ''} ${className || ''}`}>
      {label && <label className={'input__label'}>{label}</label>}
      <input
        {...otherProps}
      />
      {!valid
                && <span className={'input__error-message'}>{errorMessage || 'Validation error'}</span>}
    </div>
  )
}

export default Input

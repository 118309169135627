import React, {useContext, useEffect, useState} from "react";
import {ProductType} from "../../../types/product.type";
import {useHistory} from "react-router-dom";
import {TaskStatusType} from "../../../types/taskStatus.type";
import {Loader} from "../../../components/loader/Loader";
import {deleteTaskStatusService, getAllTaskStatusesService} from "../../../services/taskTypeService";
import {toastifyError, toastifySuccess} from "../../../components/toastify/toastify";
import {DataList} from "../../../components/List/DataList";
import {CreateTaskTypeModal} from "./CreateTaskTypeModal";
import {ContextProvider} from "../../../context";

export const TaskTypeList: React.FC = () => {
    const { setTaskTypes, taskTypes } = useContext(ContextProvider)
    const [modal, setModal] = useState<{ type?: string, item?: TaskStatusType }>({})
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState<TaskStatusType[]>([])

    const getAllItems = async () => {
        setLoading(true)
        try {
            const { status, data } = await getAllTaskStatusesService()
            if (status === 200) {
                setItems(data.items)
                setTaskTypes(data.items)
            }
        } catch (e) {

        }
        setLoading(false)
    }

    const deleteItem = async (id: number) => {
        if (!window.confirm('Are you sure?')) return

        setLoading(true)
        try {
            const { status } = await deleteTaskStatusService(id)
            if (status === 204) {
                toastifySuccess('Item has been deleted')
                await getAllItems()
            }
        } catch (e) {

        }
        setLoading(false)
    }

    useEffect(() => {
        getAllItems().then()
    }, [])

    const Item: React.FC<{ item: TaskStatusType }> = ({ item }) => {
        return (
            <>
                <td style={{ width: '100px' }}>{item.id}</td>
                <td>{item.name}</td>
                <td width={'200'}><div style={{ background: item.color, height: '25px', width: '25px', borderRadius: '5px' }} /></td>
            </>
        )
    }

  return loading ? <Loader /> :  (
      <div style={{ background: '#fff', padding: '25px', borderRadius: '10px' }}>
          <button onClick={() => setModal({ type: 'create' })} className={'btn mb15'}>Create new item</button>
          <DataList
              th={['id', 'name', 'color']}
              list={items}
              Item={(item) => <Item item={item} />}
              // link={(item: ProductType) => history.push(`/product/${item.slug}`)}
              onDelete={(item: TaskStatusType) => deleteItem(item.id)}
          />
          {modal.type && <CreateTaskTypeModal
              close={async () => setModal({})}
              updateItems={getAllItems}
          />}
      </div>
  )
}